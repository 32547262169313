<template>
    <router-link
        :to="routeToMatch"
        :tag="componentTag"
        target="_blank"
        class="w-teaser-card"
        @click.native="onClickMatch"
    >
        <div class="w-teaser-card__header bi-body-2">
            <template
                v-if="isActiveMatch && match.minute"
                class="w-teaser-card__header--live"
            >
                <span class="w-teaser-card__minute">
                    live {{ match.minute }}'
                </span>

                <span class="w-teaser-card__score">
                    {{ score }}
                </span>
            </template>

            <span
                v-else
                class="w-teaser-card__date"
            >
                <UiDate
                    :timestamp="match.time"
                    format="time"
                />

                <span>, </span>

                <span>
                    {{ matchDate }}
                </span>
            </span>
        </div>

        <div class="w-teaser-card__main">
            <div class="w-teaser-card__team">
                <UiAvatar
                    :src="getHomeTeamLogo(match)"
                    type="team"
                    size="xxs"
                />

                <div class="w-teaser-card__team-name bi-body-2">
                    {{ match.home_team.team.name }}
                </div>
            </div>

            <div class="w-teaser-card__team">
                <UiAvatar
                    :src="getAwayTeamLogo(match)"
                    type="team"
                    size="xxs"
                />

                <div class="w-teaser-card__team-name bi-body-2">
                    {{ match.away_team.team.name }}
                </div>
            </div>
        </div>

        <div class="w-teaser-card__footer">
            <span class="w-teaser-card__bets-count bi-subtitle-1">
                {{ $t('bets') }}
            </span>

            <span class="w-teaser-card__bets-count bi-overline">
                {{ match.bets_count | number }}
            </span>
        </div>
    </router-link>
</template>

<i18n>
{
    "ru": {
        "bets": "Прогнозы",
        "today": "сегодня",
        "tomorrow": "завтра"
    },
    "en": {
        "bets": "Tips",
        "today": "today",
        "tomorrow": "tomorrow"
    }
}
</i18n>

<script>
import { getHomeTeamLogo, getAwayTeamLogo } from '@/utils/team-logo'
import isObject from '@/utils/isObject'
import { isActive } from '@/utils/match'
import number from '@/filters/number'
import { isTomorrow, isToday } from '@/utils/calendar'
import isMatchPageAvailable from '@/utils/is-match-page-available'

export default {
    name: 'WTeaserCard',

    filters: {
        number
    },

    props: {
        match: {
            type: Object,
            required: true,

            validator (match) {
                return isObject(match.home_team) && isObject(match.away_team)
            }
        }
    },

    computed: {
        componentTag () {
            return this.isMatchAvailable ? 'a' : 'span'
        },


        isMatchAvailable () {
            return isMatchPageAvailable({
                dateStart: this.match.time
            })
        },

        score () {
            return `${this.match.home_team.score} : ${this.match.away_team.score}`
        },

        matchDate () {
            const matchTimeMs = this.match.time * 1000
            const matchDate = new Date(matchTimeMs)

            if (isTomorrow(matchDate)) {
                return this.$t('tomorrow')
            }

            if (isToday(matchDate)) {
                return this.$t('today')
            }

            return this.$date.transform(matchTimeMs, 'shortdate')
        },

        isActiveMatch () {
            return isActive(this.match.status.id)
        },

        routeToMatch () {
            return {
                name: 'match',
                params: {
                    id: this.match.id
                }
            }
        }
    },

    methods: {
        getHomeTeamLogo,
        getAwayTeamLogo,

        onClickMatch () {
            this.$pivik.event('teaser', 'match')
        }
    }
}
</script>

<style lang="stylus" scoped>
.w-teaser-card
    display flex
    flex-direction column
    width 14rem
    height 11.5rem
    background $cl-white
    border .1rem solid rgba($cl-primary-dark, .1)
    box-sizing border-box
    border-radius .4rem
    text-decoration none

    cursor pointer

    &__header
        display flex
        justify-content space-between
        align-items center
        padding .4rem .8rem

    &__main
        flex 10
        padding .4rem .8rem 0

    &__footer
        display flex
        justify-content space-between
        align-items center
        padding 0 .8rem
        height 2.8rem
        color $cl-primary-dark
        background-color rgba($cl-primary-dark, .1)

    &__minute
        color $cl-error

    &__date
        color $cl-text

    &__score
        color $cl-text-dark

    &__team
        display flex
        align-items center
        margin-bottom .4rem

        &-name
            color $cl-text-dark
            max-width 9.6rem
            min-width 9.6rem
            margin-left .4rem
            overflow hidden
            white-space nowrap
            text-overflow ellipsis
            font-weight $font-weight-semi-bold

</style>
