<template>
    <div class="w-teaser-swiper">
        <UiSwiper
            :slides="matches"
            :options="swiperOptions"
            @click-left="onClickLeft"
            @click-right="onClickRight"
        >
            <template #default="{ index, item }">
                <WTeaserCard
                    :key="index"
                    :match="item"
                />
            </template>
        </UiSwiper>

        <button class="w-teaser-swiper__button-prev">
            <IconArrowLeft class="arrow-icon" />
        </button>

        <button class="w-teaser-swiper__button-next">
            <IconArrowLeft class="arrow-icon" />
        </button>
    </div>
</template>

<script>
import WTeaserCard from '@/components/Widgets/Teaser/WTeaserCard.vue'
import { getHomeTeamLogo, getAwayTeamLogo } from '@/utils/team-logo'

export default {
    name: 'WTeaserSwiper',

    components: {
        WTeaserCard
    },

    props: {
        matches: {
            type: Array,
            default: () => []
        }
    },

    data () {
        return {
            swiperOptions: {
                slidesPerView: 'auto',
                slidesPerGroup: 2,
                initialSlide: 0,
                spaceBetween: 8,
                navigation: {
                    nextEl: '.w-teaser-swiper__button-next',
                    prevEl: '.w-teaser-swiper__button-prev',
                    disabledClass: 'w-teaser-swiper__button--disabled'
                }
            }
        }
    },

    methods: {
        getHomeTeamLogo,
        getAwayTeamLogo,

        onClickLeft () {
            this.$pivik.event('teaser', 'left')
        },

        onClickRight () {
            this.$pivik.event('teaser', 'right')
        }
    }
}
</script>

<style rel="stylesheet/stylus" lang="stylus">
.w-teaser-swiper
    position relative

    &__button-prev,
    &__button-next
        position absolute
        top 50%
        z-index 9
        transform translateY(-50%)
        padding 0

        width 3.2rem
        height @width
        display flex
        justify-content center
        align-items center
        background-color $cl-white
        border 1px solid $cl-secondary-light
        cursor pointer
        border-radius 100rem
        color $cl-text-dark
        outline none

        +layout-sm()
            display none

        &:hover .arrow-icon
            opacity .6

    &__button
        &-prev
            left -1rem

        &-next
            right -1rem

            & .arrow-icon
                transform rotate(180deg)

        &--disabled
            display none
</style>
