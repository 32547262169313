<template>
    <div class="loader-item bi-block bi-block--list-item">
        <div class="loader-item__header">
            <div class="loader-item__team-logo" />
            <div class="loader-item__team-logo" />

            <div class="loader-item__teams" />

            <div class="loader-item__report" />
        </div>

        <div class="loader-item__body">
            <template v-if="isMobile">
                <div class="loader-item__name loader-item__name--full" />
                <div class="loader-item__name loader-item__name--small" />

                <div class="loader-item__description loader-item__description--full" />
                <div class="loader-item__description loader-item__description--full" />
                <div class="loader-item__description loader-item__description--full" />
                <div class="loader-item__description loader-item__description--small" />
            </template>

            <template v-else>
                <div class="loader-item__name loader-item__name--small" />

                <div class="loader-item__description loader-item__description--full" />
                <div class="loader-item__description loader-item__description--small" />
            </template>
        </div>

        <div class="loader-item__footer">
            <div class="loader-item__user">
                <div class="loader-item__avatar" />

                <div class="loader-item__user-wrapper">
                    <div class="loader-item__user-name" />
                    <div class="loader-item__stat" />
                </div>
            </div>

            <div class="loader-item__footer-right">
                <div class="loader-item__button" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PredictionsLoaderItem',

    computed: {
        isMobile () {
            return ['xxs', 'xs', 'sm'].includes(this.$mq)
        }
    }
}
</script>

<style lang="stylus" scoped>
.loader-item
    display block

    &__header
        display flex
        align-items center
        border-bottom .1rem solid $cl-secondary-light
        padding .8rem .8rem .8rem 1.6rem
        height 4rem
        box-sizing border-box

        +layout-xs()
            padding .8rem

    &__team-logo
        width 1.6rem
        height 1.6rem
        background-color $cl-secondary-light
        border-radius 2rem

        &:not(:first-child)
            margin-left .4rem

    &__teams
        width 10.4rem
        height 1.2rem
        background-color $cl-secondary-light
        border-radius 2rem
        margin-left .8rem

    &__report
        width 1.6rem
        height 1.6rem
        background-color $cl-secondary-light
        border-radius 2rem
        margin-left auto

    &__body
        padding 1.2rem 1.6rem 1.6rem

        +layout-xs()
            padding .8rem .8rem 1.2rem

    &__name
        height 1.8rem
        background-color $cl-secondary-light
        border-radius 2rem
        margin-top .6rem

        &--small
            width 17.2rem
            margin-bottom 1.8rem

        &--full
            width 100%

    &__description
        height 1.2rem
        background-color $cl-secondary-light
        border-radius 2rem
        margin-top .8rem

        &--small
            width 60%
            max-width 42rem
            margin-bottom 1.8rem

        &--full
            width 100%

    &__footer
        display flex
        align-items flex-end
        justify-content space-between
        font-size 1.2rem
        line-height 1.6rem

        +layout-xs()
            align-items initial
            flex-direction column

        &-right
            display flex
            box-sizing border-box
            padding 0 1.6rem 1.6rem

            +layout-xs()
                flex-direction column
                padding 0 .8rem .8rem
                width 100%

    &__user
        display flex
        align-items center
        padding 0 1.6rem 1.6rem
        flex 1

        +layout-xs()
            padding 0 0 .8rem .8rem

    &__user-name
        width 7.4rem
        height 1rem
        background-color $cl-secondary-light
        border-radius 2rem
        margin-bottom .6rem

    &__stat
        width 5.5rem
        height 1rem
        background-color $cl-secondary-light
        border-radius 2rem

    &__user-wrapper
        display flex
        flex-direction column
        align-items baseline

    &__avatar
        width 3.2rem
        height 3.2rem
        background-color $cl-secondary-light
        border-radius .4rem
        margin-right .8rem

    &__button
        width 23.2rem
        height 4rem
        background-color $cl-secondary-light
        border-radius .8rem
        box-sizing border-box

        +layout-sm()
            width 18rem

        +layout-xs()
            width 100%
            padding 0 1.2rem

</style>
