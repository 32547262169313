import { render, staticRenderFns } from "./WTopBetsNewItem.vue?vue&type=template&id=74169b32&scoped=true&"
import script from "./WTopBetsNewItem.vue?vue&type=script&lang=js&"
export * from "./WTopBetsNewItem.vue?vue&type=script&lang=js&"
import style0 from "./WTopBetsNewItem.vue?vue&type=style&index=0&id=74169b32&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74169b32",
  null
  
)

/* custom blocks */
import block0 from "./WTopBetsNewItem.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports