/**
 * Функция для получения логотипа из объекта с данными о команде
 *
 * @param {Object} team - объект с данными о команде
 * @param {String} team.logo_small - логотип команды с маленьким разрешением
 * @param {String} team.logo - стандартный логотип команды
 *
 * @returns лого команды или пустую строку
 */
export function getTeamLogo (team) {
    if (!team) {
        return ''
    }

    return team.logo_small || team.logo || ''
}

/**
 * Функция для получения логотипа домашней команды из данных о матче
 *
 * @param {Object} match - объект с данными о матче
 * @param {Object} match.home_team - объект с общими данными о команде
 * @param {Object} match.home_team.team - объект с данными о команде
 *
 * @returns лого команды или пустую строку
 */
export function getHomeTeamLogo (match) {
    if (match && match.home_team && match.home_team.team) {
        return getTeamLogo(match.home_team.team)
    }

    return ''
}

/**
 * Функция для получения логотипа команды гостей из данных о матче
 *
 * @param {Object} match - объект с данными о матче
 * @param {Object} match.away_team - объект с общими данными о команде
 * @param {Object} match.away_team.team - объект с данными о команде
 *
 * @returns лого команды или пустую строку
 */
export function getAwayTeamLogo (match) {
    if (match && match.away_team && match.away_team.team) {
        return getTeamLogo(match.away_team.team)
    }

    return ''
}
