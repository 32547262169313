<template>
    <div class="predictions-loader">
        <PredictionsItemLoader
            v-for="i in 5"
            :key="i"
        />
    </div>
</template>

<script>
import PredictionsItemLoader from '@/components/Predictions/Loader/PredictionsItemLoader.vue'

export default {
    name: 'PredictionsLoader',

    components: {
        PredictionsItemLoader
    }
}
</script>
