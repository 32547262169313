<template>
    <div>
        <SportSelector
            v-if="!IS_BETTING_COM"
            :sports="sportsWithRoutes"
            :selected-sport-id="selectedSportId"
            @sport-changed="changeSport"
        />

        <SportSelectorDisclaimer
            v-if="!isFootballSelected"
        />

        <PredictionsLoader v-if="predictionsListStatus === PAGE_STATUSES.loading" />

        <template v-else>
            <PredictionsList
                autoload
                :interscroller="[5]"
                :native-ad-index="2"
            >
                <PredictionsItem
                    slot-scope="{ item, index }"
                    show-flag
                    :index="index"
                    :prediction="item"
                    :is-profile-page="true"
                    :hide-sponsored="firstSponsoredPredictionIndex !== index"
                />

                <template slot="zero">
                    <span v-if="isMyProfile">{{ $t('my-zero-data') }}</span>
                    <span v-else-if="isRu">{{ $t('ru-zero-data') }}</span>
                    <span v-else>{{ $t('zero-data-part1') }} <router-link :to="{ name: 'rating' }">{{ $t('rating') }}</router-link> {{ $t('zero-data-part2') }}</span>
                </template>
            </PredictionsList>
        </template>
    </div>
</template>

<i18n>
{
    "ru": {
        "header": "Профиль",
        "page-title": "%{user} — профиль пользователя, ставки, статистика и прогнозы — Betting Insider",
        "ru-zero-data": "Каппер не оставил ни одного прогноза на этот вид спорта. Проверьте ленты других видов спорта",
        "my-zero-data": "Здесь будет полная история ваших ставок и статистика по каждой линии. Начните скорее, чтобы не пропустить хорошие матчи!"
    },
    "en": {
        "header": "Profile",
        "page-title": "%{user} — user profile, bets, stats and tips — Betting Insider",
        "zero-data-part1": "This tipster hasn't made a bet. You will find many successful tipsters in",
        "rating": "the rankings",
        "zero-data-part2": "!",
        "my-zero-data": "You haven't made any tips yet. Make the first one (place a bet)!"
    }
}
</i18n>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import SportSelector from '@/components/common/SportSelector/SportSelector.vue'
import PredictionsLoader from '@/components/Predictions/Loader/PredictionsLoader.vue'
import PredictionsList from '@/components/Predictions/PredictionsList.vue'
import PredictionsItem from '@/components/Predictions/Item/PredictionsItem.vue'
import SportSelectorDisclaimer from '@/components/common/SportSelector/SportSelectorDisclaimer.vue'
import {
    SPORT_TYPE_FOOTBALL,
    IS_BETTING_COM,
    PAGE_STATUSES
} from '@/constants'

export default {
    name: 'ProfilePredictionsView',

    components: {
        SportSelector,
        PredictionsLoader,
        PredictionsItem,
        SportSelectorDisclaimer,
        PredictionsList
    },

    asyncData ({ store, route }) {
        store.dispatch('newSports/setSelectedSportId', SPORT_TYPE_FOOTBALL.id)

        const promises = [
            PredictionsList.loadPredictions(store, {
                updates: {
                    userIds: [route.params.id]
                },
                isNewSports: !store.getters['newSports/isFootballSelected'],
                reset: true
            })
        ]

        if (!IS_BETTING_COM) {
            promises.push(store.dispatch('newSports/fetchSportsIfEmpty'))
        }

        return Promise.all(promises)
    },

    data () {
        return {
            PAGE_STATUSES,
            IS_BETTING_COM
        }
    },

    head () {
        return {
            title: this.title,
            meta: [
                { id: 'og:title', property: 'og:title', content: this.title }
            ]
        }
    },

    computed: {
        ...mapGetters('profile', ['item', 'my']),
        ...mapGetters('predictions', ['firstSponsoredPredictionIndex']),
        ...mapGetters('newSports', ['isFootballSelected']),
        ...mapState('newSports', ['sports', 'selectedSportId']),
        ...mapState('predictions', ['predictionsListStatus']),

        sportsWithRoutes () {
            return this.sports.map(sport => {
                const isFootball = sport.id === SPORT_TYPE_FOOTBALL.id
                const routeName = isFootball ? 'profile' : 'profile-predictions'

                return {
                    ...sport,
                    route: {
                        name: routeName,
                        params: { sport: sport.id }
                    }
                }
            })
        },

        isRu () {
            return this.$i18n.locale === 'ru'
        },

        title () {
            return this.$t('page-title', {
                user: this.profile.name
            })
        },

        profile () {
            return this.item({ id: this.$route.params.id })
        },

        isMyProfile () {
            return this.my && this.my.id === this.profile.id
        }
    },

    methods: {
        ...mapActions({
            setSelectedSportId: 'newSports/setSelectedSportId'
        }),

        changeSport (sportId) {
            this.setSelectedSportId(sportId)

            PredictionsList.loadPredictions(this.$store, {
                updates: {
                    userIds: [this.$route.params.id]
                },
                isNewSports: !this.isFootballSelected,
                reset: true
            })
        }
    }
}
</script>
