<template>
    <div class="view-home">
        <WTeaser
            :matches="teaser"
            class="view-home__teaser"
        />

        <WBookmakers />

        <BiPageHeader class="is-not-top-padding">
            <h1 class="bi-header-4">
                {{ $t('h1') }}
            </h1>
        </BiPageHeader>

        <SportSelector
            v-if="!IS_BETTING_COM"
            :sports="sports"
            :selected-sport-id="selectedSportId"
            @sport-changed="changeSport"
        />

        <SportSelectorDisclaimer
            v-if="!isFootballSelected"
        />

        <PredictionsLoader v-if="predictionsListStatus === PAGE_STATUSES.loading" />

        <template v-else>
            <PredictionsList
                :interscroller="[3, 6]"
                :native-ad-index="10"
            >
                <template #default="{ item, index }">
                    <PredictionsItem
                        :prediction="item"
                        :index="index"
                        :hide-sponsored="firstSponsoredPredictionIndex !== index"
                        rel="nofollow"
                    />
                </template>

                <template #zero>
                    {{ $t('zero') }}
                </template>
            </PredictionsList>

            <ShowMorePredictionsButton
                v-if="!isAllPredictions"
                class="view-home__more-predictions-button"
                @click="showMorePredictions"
            />
        </template>

        <template v-if="topBets.length">
            <h2 class="view-home__top-bets-title bi-header-4">
                {{ $t('top-bets') }}
            </h2>

            <WTopBetsNew
                :bets="topBets"
                class="view-home__top-bets"
            />
        </template>

        <UiTextRoll
            v-if="hasSeo"
            :max-height="22"
            center
            class="view-home__text"
        >
            <p>Вы попали на Betting Insider – социальную сеть для любителей ставок. Здесь можно найти прогнозы на спорт, почитать аналитические материалы, изучить статистику, сравнить свои прогнозы с мнением экспертов – бывших футболистов, спортивных комментаторов.</p>
            <p>
                Сайт <router-link :to="{ name: 'home' }">
                    betting.team
                </router-link> не ограничивается спортивными прогнозами и крутым чтивом со статистикой, у нас можно делать ставки на спорт. Betting Insider – виртуальный букмекер, принимающий ставки на спорт в нашей вымышленной валюте – беткоинах.
            </p>
            <p>Мы не только позволяем сделать ставку на топовые события, но и ведем честную статистику по каждому пользователю – так вы проверите успешность своих ставок на дистанции. Прематч или лайв – играйте как вам нравится.</p>
            <p>Сейчас у нас есть следующие разделы с прогнозами на спорт:</p>
            <ul>
                <li>
                    <router-link :to="{ name: 'predictions', params: { sport: 'football' } }">
                        Прогнозы на футбол
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'predictions-new-sports', params: { sport: 'ice-hockey' } }">
                        Прогнозы на хоккей
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'predictions-new-sports', params: { sport: 'basketball' } }">
                        Прогнозы на баскетбол
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'predictions-new-sports', params: { sport: 'tennis' } }">
                        Прогнозы на теннис
                    </router-link>
                </li>
            </ul>
            <p>У кого-то обязательно возникнет вопрос: зачем нужен сайт Betting Insider, когда можно играть в настоящих букмекерских конторах на реальные деньги? У нашего проекта есть свои плюсы, главный из которых – обучение и тестирование своих стратегий перед игрой у реального букмекера. Пользователи сайта учатся ставкам на спорт, участвуют в розыгрышах призов и не рискуют при этом настоящими деньгами.</p>
            <p>
                Также на Betting Insider можно подписаться на самых успешных прогнозистов, следя за их стратегией ставок и аналитикой. Можно повторить прогноз, если аргументация покажется достаточной убедительной. Лучшие капперы собраны на вкладке «<router-link :to="{ name: 'rating' }">
                    Рейтинг
                </router-link>» в верхнем меню сайта. Также капперам будут полезны следующие разделы меню:
            </p>
            <ul>
                <li>
                    <router-link :to="{ path: 'ru/wiki' }">
                        База знаний
                    </router-link> – настоящая энциклопедия ставок, здесь есть и  развернутые статьи о букмекерских ставках, и разъяснение базовых понятий. Например, в базе знаний мы даем определения основным букмекерским терминам.
                </li>
                <li>
                    <router-link :to="{ name: 'match-center', params: { sport: 'football' } }">
                        Матч-центр
                    </router-link> – здесь собраны матчи и турниры, доступные для ставок на сайте. По умолчанию матч-центр открывается со ставками на сегодня, но можно выбрать игры на завтра или по конкретной дате в календаре. Выбирайте нужный матч –  читайте прогнозы к нему от лучших капперов сайта, а также делайте свои ставки.
                </li>
                <li>
                    <router-link :to="{ name: 'predictions', params: { sport: 'football' } }">
                        Прогнозы
                    </router-link> – в разделе можно выбрать два варианта отображения ленты – общая и подписки. Общая лента формируется из прогнозов пользователей и экспертов – бывших футболистов и комментаторов. В ленте по подпискам отображаются только прогнозы пользователей, на которых вы подписались.
                </li>
                <li>
                    <router-link :to="{ name: 'rating' }">
                        Рейтинг
                    </router-link> – на этой вкладке можно посмотреть лучших капперов среди обычных пользователей и экспертов сайта. Рейтинг пользователей можно сортировать за месяц или все время работы Betting Insider.
                </li>
                <li>
                    <router-link :to="{ path: 'ru/blog' }">
                        Блог
                    </router-link> – в этом разделе мы регулярно публикуем аналитические материалы, новости и подборки прогнозов от экспертов. Если хотите следить за беттингом и новостями букмекерского бизнеса, заглядывайте в блог.
                </li>
            </ul>
            <h2>Бесплатные прогнозы на спорт от профессионалов</h2>
            <p>
                Как вы уже поняли, на <router-link :to="{ name: 'home' }">
                    betting.team
                </router-link> можно найти прогнозы на спорт на сегодня не только от простых капперов, но и от экспертов – спортивных комментаторов и бывших игроков. Это совсем не означает, что прогнозы от профессионалов обязательно точные и принесут успех, но интересно сравнить собственный уровень с Василием Уткиным или Георгием Черданцевым.
            </p>
            <p>
                Мы ежедневно собираем бесплатные прогнозы известных личностей с разных площадок и публикуем их на Betting.Team. Теперь вы всегда можете открыть <router-link :to="{ name: 'rating-stars' }">
                    рейтинг экспертов
                </router-link> и узнать, кто из них лучше прогнозирует футбольные и другие спортивные события.
            </p>
        </UiTextRoll>
    </div>
</template>

<i18n>
{
    "ru": {
        "zero": "Прогнозов еще нет. А что думаете вы? Поделитесь своей экспертизой и собирайте подписчиков!",
        "header": "Betting Insider",
        "h1": "Лучшие прогнозы",
        "top-bets": "Популярные ставки сегодня",
        "title": "Прогнозы на спорт и ставки от профессионалов сегодня - бесплатные прогнозы на Betting Insider",
        "description": "Бесплатные прогнозы на спорт сегодня от экспертов Betting Insider. Ежедневные прогнозы от профессионалов и конкурсы прогнозов с денежными призовыми среди лучших капперов"
    },
    "en": {
        "zero": "No tips yet. What do you think about the match? Post your own tip and get new subscribers!",
        "header": "Betting Insider",
        "h1": "Best predictions",
        "top-bets": "Popular tips today",
        "title": "Sports betting and tips from experts. Professional advice for tipsters on social networks — Betting Insider",
        "description": "Make bets and win real money with Betting Insider! Tipster rankings, match odds and a lot more in iOS and Android apps."
    }
}
</i18n>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import WTopBetsNew from '@/components/Widgets/WTopBetsNew/WTopBetsNew.vue'
import BiPageHeader from '@/components/common/bi-page-header/index.vue'
import SportSelector from '@/components/common/SportSelector/SportSelector.vue'
import PredictionsList from '@/components/Predictions/PredictionsList.vue'
import PredictionsItem from '@/components/Predictions/Item/PredictionsItem.vue'
import PredictionsLoader from '@/components/Predictions/Loader/PredictionsLoader.vue'
import ShowMorePredictionsButton from '@/components/Predictions/PredictionsListLoadMoreButton.vue'
import WTeaser from '@/components/Widgets/Teaser/WTeaser.vue'
import SportSelectorDisclaimer from '@/components/common/SportSelector/SportSelectorDisclaimer.vue'
import WBookmakers from '@/components/Widgets/Bookmakers/WBookmakers.vue'
import {
    PAGE_STATUSES,
    SPORT_TYPE_FOOTBALL,
    IS_BETTING_COM
} from '@/constants'

const START_PREDICTIONS_COUNT = 5
const LOAD_PREDICTIONS_COUNT = 10

export default {
    name: 'HomeView',

    components: {
        PredictionsList,
        BiPageHeader,
        SportSelector,
        PredictionsItem,
        PredictionsLoader,
        SportSelectorDisclaimer,
        WTeaser,
        WBookmakers,
        WTopBetsNew,
        ShowMorePredictionsButton
    },

    asyncData ({ store }) {
        store.dispatch('newSports/setSelectedSportId', SPORT_TYPE_FOOTBALL.id)
        // store.dispatch('bookmakersRating/updateGeo')

        const promises = [
            PredictionsList.loadPredictions(store, {
                reset: true,
                smart: true,
                updates: {
                    count: START_PREDICTIONS_COUNT,
                    topPredictionsFirst: true
                }
            }),
            store.dispatch('match/topBets/fetchTopBets'),
            store.dispatch('widgets/bookmakers/fetchList'),
            WTeaser.loadMatches(store, { hour_range: 48, count: 41 })
        ]

        if (!IS_BETTING_COM) {
            promises.push(store.dispatch('newSports/fetchSportsIfEmpty'))
        }

        return Promise.all(promises)
    },

    data () {
        return {
            PAGE_STATUSES,
            IS_BETTING_COM
        }
    },

    head () {
        const { locale } = this.$i18n
        const hostname = process.env.DOMAIN_URL

        return {
            title: this.title,
            meta: [
                { id: 'og:title', property: 'og:title', content: this.title },
                { id: 'og:description', property: 'og:description', content: this.description },
                { id: 'description', name: 'description', content: this.description },
                { id: 'yandex-verification', name: 'yandex-verification', content: '9d7a64fed4e6df2d' }
            ],

            script: [{
                type: 'application/ld+json',
                json: {
                    '@context': 'https://schema.org/',
                    '@type': 'WebSite',
                    name: 'BETTING INSIDER',
                    url: `${hostname}/${locale}`,
                    potentialAction: {
                        '@type': 'SearchAction',
                        target: `${hostname}/${locale}/search?query={search_term_string}`,
                        'query-input': 'required name=search_term_string'
                    }
                }
            }]
        }
    },

    computed: {
        ...mapState('widgets/teaser', ['teaser']),
        ...mapState('newSports', ['sports', 'selectedSportId']),
        ...mapState('predictions', ['predictionsListStatus']),
        ...mapState('match/topBets', ['topBets']),

        ...mapGetters('predictions', ['firstSponsoredPredictionIndex', 'isAllPredictions']),
        ...mapGetters('newSports', ['isFootballSelected']),

        isRu () {
            return this.$i18n.locale === 'ru'
        },

        title () {
            return this.$t('title')
        },

        description () {
            return this.$t('description')
        },

        hasSeo () {
            return this.isRu
        }
    },

    methods: {
        ...mapActions({
            setFilter: 'predictions/filter',
            setSelectedSportId: 'newSports/setSelectedSportId'
        }),

        showMorePredictions () {
            this.setFilter({
                isNewSports: !this.isFootballSelected,
                updates: {
                    topPredictionsFirst: true,
                    count: LOAD_PREDICTIONS_COUNT
                },
                reset: false,
                smart: true
            })
        },

        changeSport (sportId) {
            this.setSelectedSportId(sportId)

            PredictionsList.loadPredictions(this.$store, {
                isNewSports: !this.isFootballSelected,
                reset: true,
                smart: true,
                updates: {
                    count: START_PREDICTIONS_COUNT,
                    topPredictionsFirst: true
                }
            })
        }
    }
}
</script>

<style lang="stylus" scoped>
.view-home
    &__teaser
        margin-bottom 4rem

    &__btn
        margin-top 2.4rem

    &__text
        padding 2.4rem 0

    &__more-predictions-button
        margin 1.6rem auto 4rem

        +layout-xs-and-up()
            width 28rem

    &__top-bets
        margin-bottom 1.6rem

        &-title
            margin 0rem 0 1.6rem

</style>
