/**
 * Функция для получения объекта с данными для микроразметки SportsEvent
 *
 * @param {Object} config
 * @param {String} config.startDate - дата начала матча в формате YYYY-MM-DDTHH:mm:ss.sssZ
 * @param {String} config.homeTeamName - название "домашней" команды
 * @param {String} config.awayTeamName - название команды "гостей"
 * @param {String} config.stadiumName - название стадиона где проходит игра
 * @param {String} config.stadiumAddress - адрес стадионе проведения матча
 * @param {String} config.matchURL - полный URL для страницы матча
 * @param {String} config.description - краткое описание матча вида "Футбольный матч Команда1 - Команда2"
 *
 * @returns Object - {
 * "@context":"https://schema.org","@type":"SportsEvent",
 * "location":{"@type":"Place","name":"[stadiumName]","address":"[stadiumAddres]"},
 * "startDate":"[startDate]",
 * "name":"[homeTeamName-awayTeamName]",
 * "url": "[matchURL]",
 * "homeTeam":{"@type":"SportsTeam","name":"[homeTeamName]"},
 * "awayTeam":{"@type":"SportsTeam","name":"[awayTeamName]"},
 * "description":"[description]"
 * }
 */
export default function getSportEventJsonld (config = {}) {
    const {
        startDate,
        homeTeamName,
        awayTeamName,
        stadiumName,
        stadiumAddress,
        matchURL,
        description
    } = config

    const jsonLd = {
        '@context': 'https://schema.org',
        '@type': 'SportsEvent',
        startDate,
        name: `${homeTeamName} - ${awayTeamName}`,
        url: matchURL,
        homeTeam: {
            '@type': 'SportsTeam',
            name: homeTeamName
        },
        awayTeam: {
            '@type': 'SportsTeam',
            name: awayTeamName
        },
        description
    }

    if (stadiumName || stadiumAddress) {
        jsonLd.location = {
            '@type': 'Place',
            name: stadiumName,
            address: stadiumAddress
        }
    }

    return jsonLd
}
