<template>
    <section class="top-bets">
        <ul class="top-bets__list">
            <li
                v-for="(bet, index) in bets"
                :key="index"
                class="top-bets__list-item"
            >
                <MatchTopBetsItem
                    :bet="bet"
                    :show-match-info="showMatchInfo"
                />
            </li>
        </ul>
    </section>
</template>

<script>
import MatchTopBetsItem from '@/components/Widgets/WTopBetsNew/WTopBetsNewItem.vue'

export default {
    name: 'WTopBetsNew',

    components: {
        MatchTopBetsItem
    },

    props: {
        bets: {
            type: Array,
            required: true
        },

        showMatchInfo: {
            type: Boolean,
            default: true
        }
    }
}
</script>


<style lang="stylus" scoped>
.top-bets
    &__list
        display grid
        grid-gap .8rem

        list-style none
        padding 0
        margin 0

        +layout-sm-and-up()
            grid-template-columns 1fr 1fr

</style>
