<template>
    <MatchLink
        v-if="isValidBet"
        :match="computedMatchForMatchLink"
        :to="predictionsByOddIdRoute"
        class="top-bets-item"
        @click.native="onClick"
    >
        <div
            v-if="showMatchInfo"
            class="top-bets-item__header"
        >
            <UiAvatar
                :src="homeTeamLogo"
                type="team"
                size="xxs"
                class="top-bets-item__team-logo"
            />

            <UiAvatar
                :src="awayTeamLogo"
                type="team"
                size="xxs"
                class="top-bets-item__team-logo"
            />

            <span class="top-bets-item__match-name bi-overline">
                {{ matchName }}
            </span>
        </div>

        <div class="top-bets-item__body bi-header-3">
            <div class="top-bets-item__odds-rate">
                <BetRate :rate="bet.odds.rate" />
            </div>

            <div class="top-bets-item__odds-name">
                {{ bet.odds.fullName }}
            </div>
        </div>

        <div class="top-bets-item__footer bi-subtitle-2">
            <div class="top-bets-item__percent">
                {{ $tc('percent', bet.percent) }}
            </div>

            <div class="top-bets-item__predictions">
                {{ $tc('predictions', bet.count) }}
            </div>
        </div>
    </MatchLink>
</template>

<i18n>
{
    "ru": {
        "percent": "%{n}% капперов поставили",
        "predictions": "%{n} прогноз | %{n} прогноза | %{n} прогнозов"
    },
    "en": {
        "percent": "%{n}% of tipsters bet on",
        "predictions": "%{n} prediction | %{n} predictions"
    }
}
</i18n>

<script>
import BetRate from '@/components/Bet/BetRate.vue'
import MatchLink from '@/components/Match/MatchLink.vue'

export default {
    name: 'WTopBetsNewItem',

    components: {
        BetRate,
        MatchLink
    },

    props: {
        bet: {
            type: Object,
            required: true
        },

        showMatchInfo: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        isValidBet () {
            return this.bet?.odds?.id
                && this.bet?.match?.home?.team
                && this.bet?.match?.away?.team
        },

        predictionsByOddIdRoute () {
            return {
                name: 'predictions-by-odd-id',
                params: {
                    oddId: this.bet.odds.id,
                    matchId: this.matchId
                }
            }
        },

        match () {
            return this.bet.match
        },

        computedMatchForMatchLink () {
            return { ...this.match, id: this.match?.ubersetzer?.sportsId || this.match.id }
        },

        homeTeam () {
            return this.match.home.team
        },

        awayTeam () {
            return this.match.away.team
        },

        homeTeamLogo () {
            return this.homeTeam.logo?.resize
        },

        awayTeamLogo () {
            return this.awayTeam.logo?.resize
        },

        matchName () {
            return `${this.homeTeam.name} - ${this.awayTeam.name}`
        },

        matchId () {
            return this.match.id
        }
    },

    methods: {
        onClick () {
            this.$pivik.event('match', 'stats_widget', 'click_top-5_odds')
        }
    }
}
</script>

<style lang="stylus" scoped>
.top-bets-item
    display flex
    flex-direction column
    height 100%
    color inherit
    border .1rem solid $cl-secondary-light
    border-radius .8rem
    box-sizing border-box

    &:hover,
    &:active,
    &:focus
        text-decoration none

    &__header
        display flex
        padding 1.4rem 1.2rem 0

    &__team-logo
        &:not(:first-child)
            margin-left .4rem

    &__match-name
        color $cl-text
        margin-left .8rem

    &__body
        display flex
        flex 1
        padding 1.2rem 1.2rem 0

    &__odds-rate
        color $cl-primary
        min-width 4.4rem

    &__odds-name
        margin-left .8rem

    &__footer
        display flex
        justify-content space-between
        padding .8rem 1.2rem 1.2rem

    &__percent
        color $cl-secondary-dark

    &__predictions
        color $cl-info

</style>
