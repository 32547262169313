<template>
    <button
        ref="showMoreButton"
        class="more-predictions-button"
        @click="onClick"
    >
        {{ $t('more') }}
    </button>
</template>

<i18n>
{
    "ru": {
        "more": "Показать еще"
    },
    "en": {
        "more": "Show more"
    }
}
</i18n>

<script>
export default {
    name: 'ShowMoreButton',
    data () {
        return {
            intersectionObserver: null
        }
    },

    mounted () {
        this.intersectionObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    this.$pivik.event('more_button', 'predictions', 'view', 'empty_string')
                }
            })
        })

        this.$nextTick(() => {
            this.intersectionObserver.observe(this.$refs.showMoreButton)
        })
    },

    beforeDestroy () {
        this.intersectionObserver.unobserve(this.$refs.showMoreButton)
    },

    methods: {
        onClick () {
            this.$emit('click')
            this.$pivik.event('more_button', 'predictions', 'click', 'empty_string')
        }
    }
}
</script>

<style lang="stylus" scoped>
.more-predictions-button
    button(6, 'contained-light', 'primary')
    display block
    height 5.6rem
    width 100%
    letter-spacing .1rem

    +layout-xs-and-up()
        width 28rem
</style>
