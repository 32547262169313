<template>
    <div
        v-if="!hasError"
        class="w-teaser"
    >
        <div class="w-teaser__header">
            <h2 class="bi-header-4 w-teaser__title">
                {{ $t('title') }}
            </h2>

            <router-link
                :to="matchCenterRoute"
                class="w-teaser__all-link link-text-inner bi-button"
            >
                <span>{{ $t('all') }}</span>
            </router-link>
        </div>

        <WTeaserSwiper :matches="matches" />
    </div>
</template>

<i18n>
{
    "ru": {
        "all": "Все матчи",
        "title": "На это ставят",
        "microdata-description": "%{homeTeam} — %{awayTeam}: прогнозы и ставки на матч"
    },
    "en": {
        "all": "All matches",
        "title": "Most bet on",
        "microdata-description": "Football tips & odds %{homeTeam} vs %{awayTeam}"
    }
}
</i18n>

<script>
import { mapState } from 'vuex'
import get from 'lodash.get'
import WTeaserSwiper from '@/components/Widgets/Teaser/WTeaserSwiper.vue'
import getSportsEventJsonld from '@/utils/get-sports-event-jsonld'

export default {
    name: 'WTeaser',

    components: {
        WTeaserSwiper
    },

    props: {
        matches: {
            type: Array,
            default: () => []
        }
    },

    head () {
        if (this.$route.name === 'match') {
            return {}
        }

        const hostname = process.env.DOMAIN_URL

        const scriptList = this.matches.map(matchData => {
            let matchURL = ''

            try {
                const matchRoute = this.$router.resolve({
                    name: 'match',
                    params: {
                        id: matchData.id
                    }
                })

                matchURL = `${hostname}${matchRoute.href}`
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error('[WTeaser]: can`t parse match url')
            }

            const homeTeamName = get(matchData, 'home_team.team.name', '')
            const awayTeamName = get(matchData, 'away_team.team.name', '')
            const description = this.$t('microdata-description', {
                homeTeam: homeTeamName,
                awayTeam: awayTeamName
            })

            const json = getSportsEventJsonld({
                startDate: new Date(matchData.time * 1000).toISOString(),
                stadiumName: get(matchData, 'stadium.name', ''),
                stadiumAddress: get(matchData, 'stadium.city', ''),
                homeTeamName,
                awayTeamName,
                matchURL,
                description
            })

            return {
                type: 'application/ld+json',
                json
            }
        })

        return {
            script: scriptList
        }
    },

    computed: {
        ...mapState('widgets/teaser', ['hasError']),

        matchCenterRoute () {
            return {
                name: 'match-center',
                params: {
                    sport: 'football'
                }
            }
        }
    },

    loadMatches (store, options) {
        return store.dispatch('widgets/teaser/fetch', options)
            .catch(() => {})
    }
}
</script>

<style lang="stylus" scoped>
.w-teaser
    margin-bottom 2.8rem
    z-index 1

    &__header
        display flex
        justify-content space-between
        align-items flex-end
        margin-bottom 1.6rem

    &__title
        margin 0

    &__all-link
        line-height 2.2rem

</style>
